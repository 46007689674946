import React,{useEffect,useState} from 'react'
import * as queryString from "query-string";
import { useLocation } from '@reach/router';
import {useDispatch,useSelector} from 'react-redux'
import { getVanBanItem,getListVBItem,postVanBanLQ,updateVanBanLQ,updateVanBanItem,setUpdateVB } from '../actions/userActions';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {Link} from 'gatsby'
import Layout from '../components/layout'
import Footer from '../components/footer'
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  Container, Typography, Table, TableBody, TableRow, TableCell,Stack,
  List,ListItem,ListItemText,ListItemIcon,Box,Button,TextField,Autocomplete,
  IconButton,Divider
} from '@mui/material';
import _ from 'lodash'
import dayjs from 'dayjs'

function Details() {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const dispatch = useDispatch()
  const {accessToken,isAdmin,userInfo} = useSelector( (state) => state.auth)
  const {vanBanItem,vanBanDetails} = useSelector( (state) => state.contents)
  
  const [isEdit, setEdit] = useState(false)
  const [vanBanLQ, setVanBanLQ] = useState([])
  useEffect(() => {
    if(!_.isEmpty(accessToken) ){
      dispatch(getVanBanItem(id,accessToken))
    }
  }, [accessToken,id])
  useEffect(()=>{
    if(isAdmin){
      dispatch(getListVBItem(id,accessToken))
    }
  },[isAdmin])
  function handleVanBanLQ(token) {
    if(vanBanLQ.length>0){
      const VBLienQuan = vanBanLQ.map(x=>x._id)
      dispatch(postVanBanLQ({id,VBLienQuan},token))
      setVanBanLQ([])
    }
  }
  function handleDelete(item) {
    dispatch(updateVanBanLQ({id,item},accessToken))
  }
  const handleTepdinhkem = (value) => {
    const urlCheck = queryString.parseUrl(decodeURI(value))
    if(!_.isEmpty(urlCheck.query)){
      dispatch(updateVanBanItem({
        TepDinhKem: decodeURI(value),
        TenTep: urlCheck.query.id.replace(urlCheck.query.parent+'/','')
      }))
    }
  }

  const handleSaveVB = () => {
    setEdit(false)
    dispatch(setUpdateVB(vanBanItem,accessToken))
  }

  return(
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
      {vanBanItem ? 
        <Container>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} pt={2} pb={2}>
            <Link to="/" >
              <img
                src="https://hunghau.vn/wp-content/uploads/2022/02/logo-HHH-new.png"
                width={100}
                height={100}
                alt="Logo HungHau"
              />
            </Link>
            <div>
              <Link to="https://hunghau.vn" target="_blank" style={{textDecoration:'none'}}><Typography variant="h3" color="#0565ac"><strong>hunghau.vn</strong></Typography></Link>
              {""}
              <Typography textAlign="center">{dayjs().format("dddd, DD/MM/YYYY")}</Typography>
            </div>
          </Stack>
          <Typography variant="h5" gutterBottom>
            Quyết định số {vanBanItem.KyHieu} của {vanBanItem.NoiBH}: {vanBanItem.TrichYeu}
          </Typography>
          <Table sx={{border:1,borderColor:'rgba(224, 224, 224, 1)'}}>
            <TableBody >
              <TableRow>
                <TableCell width="35%" style={{backgroundColor:'#f9f9f9'}} align="left">Số, ký hiệu</TableCell>
                <TableCell component="th" align="left">
                  {isEdit ? 
                    <TextField
                      required
                      fullWidth
                      value={vanBanItem.KyHieu}
                      onChange={(val) => dispatch(updateVanBanItem({KyHieu:val.target.value}))}
                    />
                  : vanBanItem.KyHieu}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="35%" style={{backgroundColor:'#f9f9f9'}} align="left">Ngày ban hành</TableCell>
                <TableCell  align="left">
                  {isEdit ? 
                    <MobileDatePicker
                      label="Ngày ban hành"
                      fullWidth
                      inputFormat="DD/MM/YYYY"
                      value={vanBanItem.NgayBanHanh}
                      onChange={(val) => dispatch(updateVanBanItem({NgayBanHanh:val}))}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  : dayjs(vanBanItem.NgayBanHanh).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="35%" style={{backgroundColor:'#f9f9f9'}} align="left">Loại văn bản</TableCell>
                <TableCell  align="left">
                  {isEdit ? 
                      <TextField
                        required
                        fullWidth
                        value={vanBanItem.LoaiVB}
                        onChange={(val) => dispatch(updateVanBanItem({LoaiVB:val.target.value}))}
                      />
                    : vanBanItem.LoaiVB}  
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="35%" style={{backgroundColor:'#f9f9f9'}} align="left">Cơ quan ban hành</TableCell>
                <TableCell align="left">
                  {isEdit ? 
                      <TextField
                        required
                        fullWidth
                        value={vanBanItem.NoiBH}
                        onChange={(val) => dispatch(updateVanBanItem({NoiBH:val.target.value}))}
                      />
                    : vanBanItem.NoiBH}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="35%" style={{backgroundColor:'#f9f9f9'}} align="left">Trích yếu</TableCell>
                <TableCell align="left">
                  {isEdit ? 
                    <TextField
                      required
                      fullWidth
                      value={vanBanItem.TrichYeu}
                      onChange={(val) => dispatch(updateVanBanItem({TrichYeu:val.target.value}))}
                    />
                  : vanBanItem.TrichYeu}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="35%" style={{backgroundColor:'#f9f9f9'}} align="left">Tài liệu đính kèm</TableCell>
                <TableCell align="left">
                  {isEdit ? 
                    <>
                      <TextField
                        required
                        fullWidth
                        value={vanBanItem.TepDinhKem}
                        onChange={(val) => handleTepdinhkem(val.target.value)}
                      />
                      <Link to={vanBanItem.TenTep}>{vanBanItem.TenTep}</Link>
                    </>
                  : <Link to={vanBanItem.TepDinhKem} target="_blank" rel="noopener noreferrer">{vanBanItem.TenTep}</Link>}
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {isAdmin ? 
            userInfo.roles.includes(vanBanItem.NoiBH) ?
            <Stack direction="row" sx={{pt:3}} spacing={2}>
              <Autocomplete 
                multiple
                options={_.isEmpty(vanBanDetails)?[]:vanBanDetails}
                getOptionLabel={(option) => option.TrichYeu}
                filterSelectedOptions
                fullWidth
                value={vanBanLQ}
                onChange={(e,val)=>setVanBanLQ(val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Văn bản"
                    fullWidth
                    placeholder="Tìm kiếm văn bản..."
                  />
                )}
              />
              <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={()=>handleVanBanLQ(accessToken)}>Thêm văn bản liên quan</Button>
              {isEdit ? 
                <>
                  <Button variant="outlined" color="success" startIcon={<AddCircleOutlineIcon />} onClick={handleSaveVB}>Lưu</Button>
                  <Button variant="outlined" color="error" startIcon={<AddCircleOutlineIcon />} onClick={()=>setEdit(false)}>Huỷ</Button>
                </> :
                <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={()=>setEdit(true)}>Sửa văn bản</Button> }
            </Stack> : null : null}
          <Typography variant="h5" gutterTop style={{paddingTop:50}}>
            VĂN BẢN LIÊN QUAN
          </Typography>
          <List component="nav">
            {vanBanItem.hasOwnProperty("VBLienQuan")? vanBanItem.VBLienQuan.length > 0 ? vanBanItem.VBLienQuan.map(x=>
              <>
                <ListItem 
                  secondaryAction={ isAdmin ?
                    <IconButton edge="end" aria-label="delete" onClick={()=>handleDelete(x._id)}>
                      <DeleteIcon />
                    </IconButton> : null
                  }>
                    <ListItemIcon>
                      <ArrowRightIcon/>
                    </ListItemIcon>
                    <ListItemText>
                      <Link to={`/details?id=${x._id}`} style={{textDecoration:'none'}}>
                        {x.TrichYeu}
                      </Link>
                    </ListItemText>
                  </ListItem>
                  {isAdmin ? <Divider variant="inset" component="li" /> : null}
              </>
                
            ):null : null}
          </List>
        </Container>
       : null}
      <Footer />
      </Box>
    </Layout>
  )
}
export default Details